import { BrowserRouter, Route, Routes} from "react-router-dom";
import Main from "./components/Main";
import LackyJet from "./components/LackyJet";
import Mines from "./components/Mines";
import DemoCoinFlip from "./components/DemoCoinFlip";
import { Context } from "./context";
import { useState } from "react";
import Crash from "./components/Crash";

function App() {
  const [lang,setlang]=useState('ru')
  
  return (
    <div style={{ height: '100%' }}>
      <Context.Provider value={{lang,setlang}}>
        <BrowserRouter>
          <Routes>         
            <Route path="/main" element={<Main />} />
            <Route path="/lackyjet" element={<LackyJet />} />
            <Route path="/mines" element={<Mines />} />
            <Route path="/demoCoinFlip" element={<DemoCoinFlip />} />
            <Route path="/crash" element={<Crash />} />
            <Route path="/" element={<Main  />} /> {/* При точном совпадении пути "/" отображаем компонент Main */}
          </Routes>
        </BrowserRouter>
      </Context.Provider>
    </div>
  );
}

export default App;
