import minesUp from '../images/main/logos_svg/logomines.svg'
import '../styles/Mines_style.css'
import animation from '../images/maines/animation.gif'
import { Link } from 'react-router-dom';
import signal from '../images/maines/button.svg'
import '../images/maines/results/1.png'
import { useContext, useEffect, useState } from 'react';
import logo from '../images/maines/logomines.svg';
import logodrop from '../images/maines/logodrop.gif';
import fullAnimation from '../images/maines/fullAnimation.gif'
import { Context } from '../context';
import { languages } from '../language';


function Mines() {
   const {lang,setlang} = useContext(Context)

      const list =[
        {img:require('../images/maines/signalsgif/1.gif'),text:"1"},
        {img:require('../images/maines/signalsgif/2.gif'),text:"1"},
        {img:require('../images/maines/signalsgif/3.gif'),text:"1"},
        {img:require('../images/maines/signalsgif/4.gif'),text:"1"},
        {img:require('../images/maines/signalsgif/5.gif'),text:"1"},
        {img:require('../images/maines/signalsgif/6.gif'),text:"1"},
        {img:require('../images/maines/signalsgif/7.gif'),text:"1"},
        {img:require('../images/maines/signalsgif/8.gif'),text:"1"},
        {img:require('../images/maines/signalsgif/9.gif'),text:"1"},
        {img:require('../images/maines/signalsgif/10.gif'),text:"1"},
        {img:require('../images/maines/signalsgif/11.gif'),text:"1"},
        {img:require('../images/maines/signalsgif/12.gif'),text:"1"},
        {img:require('../images/maines/signalsgif/13.gif'),text:"1"},
        {img:require('../images/maines/signalsgif/14.gif'),text:"1"},
        {img:require('../images/maines/signalsgif/15.gif'),text:"1"},
        {img:require('../images/maines/signalsgif/16.gif'),text:"1"},
        {img:require('../images/maines/signalsgif/17.gif'),text:"1"},
        {img:require('../images/maines/signalsgif/18.gif'),text:"1"},
        {img:require('../images/maines/signalsgif/19.gif'),text:"1"},
        {img:require('../images/maines/signalsgif/20.gif'),text:"1"},
        {img:require('../images/maines/signalsgif/21.gif'),text:"1"},
        {img:require('../images/maines/signalsgif/22.gif'),text:"1"},
        {img:require('../images/maines/signalsgif/23.gif'),text:"1"},
        {img:require('../images/maines/signalsgif/24.gif'),text:"1"},
      ]

      const list_5 =[
        {img:require('../images/maines/signals_5/1.gif'),text:"1"},
        {img:require('../images/maines/signals_5/2.gif'),text:"1"},
        {img:require('../images/maines/signals_5/3.gif'),text:"1"},
        {img:require('../images/maines/signals_5/4.gif'),text:"1"},
        {img:require('../images/maines/signals_5/5.gif'),text:"1"},
        {img:require('../images/maines/signals_5/6.gif'),text:"1"},
        {img:require('../images/maines/signals_5/7.gif'),text:"1"},
        {img:require('../images/maines/signals_5/8.gif'),text:"1"},
        {img:require('../images/maines/signals_5/9.gif'),text:"1"},
        {img:require('../images/maines/signals_5/10.gif'),text:"1"},
        {img:require('../images/maines/signals_5/11.gif'),text:"1"},
        {img:require('../images/maines/signals_5/12.gif'),text:"1"},
        {img:require('../images/maines/signals_5/13.gif'),text:"1"},
        {img:require('../images/maines/signals_5/14.gif'),text:"1"},
        {img:require('../images/maines/signals_5/15.gif'),text:"1"},
      ]

      const list_7 =[
        {img:require('../images/maines/signals_7/1.gif'),text:"1"},
        {img:require('../images/maines/signals_7/2.gif'),text:"1"},
        {img:require('../images/maines/signals_7/3.gif'),text:"1"},
        {img:require('../images/maines/signals_7/4.gif'),text:"1"},
        {img:require('../images/maines/signals_7/5.gif'),text:"1"},
        {img:require('../images/maines/signals_7/6.gif'),text:"1"},
        {img:require('../images/maines/signals_7/7.gif'),text:"1"},
        {img:require('../images/maines/signals_7/8.gif'),text:"1"},
        {img:require('../images/maines/signals_7/9.gif'),text:"1"},
        {img:require('../images/maines/signals_7/10.gif'),text:"1"},
        {img:require('../images/maines/signals_7/11.gif'),text:"1"},
        {img:require('../images/maines/signals_7/12.gif'),text:"1"},
        {img:require('../images/maines/signals_7/13.gif'),text:"1"},
        {img:require('../images/maines/signals_7/14.gif'),text:"1"},
        {img:require('../images/maines/signals_7/15.gif'),text:"1"},
      ]

    const [showLogo, setShowLogo] = useState(true)
    //const [showLogo, setShowLogo] = useState(false)
    const [showingLogoDrop, setShowingLogoDrop] = useState(false)
    const [showFullAnimation,setshowFullAnimation]=useState(false)
    const [showResult,setshowResult]=useState(false)
    const [flagFullAnimation,setflagFullAnimation] = useState(0)
    const [radomElement,setRandomElement]=useState(list[Math.floor(Math.random() * list.length)])
    const [showButton,setShowButton] = useState(true)
    const [showQuantityMines,setshowQuantityMines]= useState(true)
    const [quantityMines,setQuantityMines] = useState(3)

    const [remainingTime, setRemainingTime] = useState(60); 

    const [showingAnimation,setShowingAnimation] = useState(false)
    const data=languages[lang]

    const randomItem = radomElement;
    



    const timeToStart=()=>{
        const intervalId = setInterval(() => {
            setRemainingTime(prevTime => {
              const updatedTime = prevTime - 1; // Уменьшаем время на 1 секунду
              if (updatedTime <= 0) {
                clearInterval(intervalId); // Останавливаем интервал, если время истекло
              }
              return updatedTime;
            });
          }, 1000);


        setShowButton(false)
        setTimeout(() => {                  
           setShowButton(true)
        }, 300000);

    }



    const pushArray = () => {
        setRemainingTime(60)
        if(quantityMines==5){
            setRandomElement(list_5[Math.floor(Math.random() * list_5.length)])
        }

        if(quantityMines==7){
            setRandomElement(list_7[Math.floor(Math.random() * list_7.length)])
        }

        setshowQuantityMines(false)
        if(remainingTime===0){
            setRemainingTime(60)
        }
    //    setRandomElement(Math.floor(Math.random() * list.length))
    if(flagFullAnimation===0){
        //setRandomElement(Math.floor(Math.random() * list.length))

        if(flagFullAnimation===0){
            setshowFullAnimation(true)
            setflagFullAnimation(1)  
        }
        setShowLogo(false)
        setTimeout(() => {                  
            setshowFullAnimation(false); // Скрываем анимацию после завершения
            setshowResult(true)
        }, 900);//3800

        timeToStart();

    }
    else{
        setshowResult(false)
        setShowingAnimation(true)

        timeToStart();
          
        setTimeout(() => {          
            setShowingAnimation(false); // Скрываем анимацию после завершения
            setshowResult(true)
        }, 2400);
        }

    };


    const gradientStyle = {
        background: 'linear-gradient(to right, #F4D04B, #D46D50)',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
        textDecoration: 'none'
    };

    const plus = () => {
        if(quantityMines<7)
        setQuantityMines(quantityMines+2)
    }

    const minus = () => {
        if(quantityMines>3)
        setQuantityMines(quantityMines-2)
    }
    return (
      <div className='minesMain'>    
        <img src={minesUp} className='minesUp'></img>
        {showLogo && ( // Показываем анимацию, если showingAnimation равна true
                <img src={logo} className='logo_mines' alt="Animation" />
        )}
        {showFullAnimation && flagFullAnimation && ( // Показываем анимацию, если showingAnimation равна true
                <img src={fullAnimation} alt="Animation" />
                
        )}
        {showingAnimation && ( // Показываем анимацию, если showingAnimation равна true
            <img src={animation} alt="aaaa" />
                
        )}
        {showResult && ( // Показываем анимацию, если showingAnimation равна true
            <img src={randomItem.img} alt={randomItem.text} />
                
        )}

        {showButton && ( // Показываем анимацию, если showingAnimation равна true
            <img src={signal} onClick={pushArray} className='buttonMines'></img>
                
        )}
        {remainingTime !=60 && remainingTime !=0 && (
            <div className='timedivstart'>
                <span className='timestartUp'> {data.timeStartMines}  </span>
                <span className='timestartDown'> {remainingTime%60} {data.seconds} </span>
            </div>

        )}
        {showQuantityMines&&(
            <div className='div_quantity_mines'>
                <div className='div_quantity_mines_plus' onClick={minus}><span className='div_quantity_mines_text margin_minus'> _ </span></div>
                <div className='div_quantity'><span className='div_quantity_text'>{quantityMines}</span></div>
                <div className='div_quantity_mines_minus' onClick={plus}><span className='div_quantity_mines_text'> + </span></div>
            </div>
        )}
        
        {remainingTime===0&&(
            <img src={signal} onClick={pushArray} className='buttonMines'></img>
        )}

        {/* Отображение содержимого массива */}

        <Link to="/main" className="menu_mines" style={{ textDecoration: 'none' }}>
            <span className='menu_rotate'>↺</span>
            <span className='text_menu_mines' > {data.menu} </span>
        </Link>
      </div>
    );
  }
  
  export default Mines;